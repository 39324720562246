import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/theme/src/layouts/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div style={{
      "display": "flex",
      "justifyContent": "center",
      "height": "55vh"
    }}>
  <div style={{
        "display": "flex",
        "alignItems": "center"
      }}>
    <pre>
      <inlineCode style={{
            "fontSize": "1rem",
            "padding": "0.5rem 1rem"
          }}>
        yarn add @josefaidt/gatsby-theme
      </inlineCode>
    </pre>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      